var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form }, on: { submit: _vm.handleSubmit } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "Note",
                        labelCol: { span: 7 },
                        wrapperCol: { span: 15 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "note",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "Please input your note!",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "['note',{rules: [{ required: true, message: 'Please input your note!' }]}]",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "Gender",
                        labelCol: { span: 7 },
                        wrapperCol: { span: 15 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "gender",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please select your gender!",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "['gender',{rules: [{ required: true, message: 'Please select your gender!' }]}]",
                            },
                          ],
                          attrs: {
                            placeholder:
                              "Select a option and change input text above",
                          },
                          on: { change: this.handleSelectChange },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "male" } }, [
                            _vm._v("male"),
                          ]),
                          _c(
                            "a-select-option",
                            { attrs: { value: "female" } },
                            [_vm._v("female")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "Gender",
                        labelCol: { span: 7 },
                        wrapperCol: { span: 15 },
                      },
                    },
                    [
                      _c("a-cascader", {
                        attrs: {
                          options: _vm.areaOptions,
                          showSearch: { filter: _vm.filter },
                          placeholder: "Please select",
                        },
                        on: { change: _vm.onChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { wrapperCol: { span: 12, offset: 5 } } },
            [
              _c(
                "a-col",
                { attrs: { md: 24, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { wrapperCol: { span: 12, offset: 5 } } },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", htmlType: "submit" } },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }